import React, { useEffect, useState } from 'react';
import './ProfilePic.css'; // CSS file for styling
import dura from './../../assets/dura.png';
import ApiService from '../../services/ApiService';

const frames = await ApiService.getFrames();

const ProfilePic = ({ isBig, account }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [frame, setFrame] = useState({});
    const [profileImageUrl, setProfileImageUrl] = useState(null);
    const [isOnline, setIsOnline] = useState(false);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const handleImageError = () => {
        setProfileImageUrl(null);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                var selectedFrameId = localStorage.getItem("selectedPicFrame");
                if (account != null) {
                    if (account.banned) return;
                    selectedFrameId = account.selectedPicFrame;
                }

                frames.forEach((frame) => {
                    if (frame.id == selectedFrameId) {
                        setFrame(frame);
                    }
                });
            } catch (error) {
                console.error('Error fetching frames:', error);
            }

            try {
                var profileImageUrl = `https://api.animix.lol/account/getProfileData/${localStorage.getItem("profileId")}_profile_pic.png`;
                if (account != null) {
                    profileImageUrl = `https://api.animix.lol/account/getProfileData/${account.id}_profile_pic.png`;
                }

                setProfileImageUrl(profileImageUrl);
            } catch (error) {
                console.error('Error fetching profile image:', error);
            }
        };

        const checkOnlineStatus = () => {
            if (account && account.lastWatchedTime) {
                const lastWatchedTime = new Date(account.lastWatchedTime);
                const currentTime = new Date();
                const differenceInMinutes = (currentTime - lastWatchedTime) / (1000 * 60);

                console.log("dif " + differenceInMinutes)
                setIsOnline(differenceInMinutes <= 5);
            }
        };

        fetchData();
        checkOnlineStatus();

        return () => {
            // Cleanup function if needed
        };
    }, [account]);

    return (
        <div className="profile-pic-container">
            {profileImageUrl ? (
                <img
                    className={`profile-pic ${imageLoaded ? 'loaded' : ''} ${isBig ? 'big' : ''}`}
                    src={profileImageUrl}
                    alt="Profile"
                     loading="lazy"
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                />
            ) : (
                <img
                    className={`profile-pic loaded placeholder ${isBig ? 'big' : ''}`}
                    src={dura}
                    loading="lazy"
                    alt="Placeholder"
                />
            )}
            {isOnline && <div style={{position: "absolute"}} className={`online-indicator ${isBig ? 'big' : ''}`} />}
            {frame.url && <img  loading="lazy" className={`overlay ${isBig ? 'big' : ''}`} src={frame.url} alt="Frame" />}
        </div>
    );
};

export default ProfilePic;
