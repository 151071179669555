import React, { useState, useEffect } from 'react';
import appPromo from '../assets/app-promo.png';
const AppPromo = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const appPromoShown = localStorage.getItem('appPromoShown') === "true";
        const isMobileDevice = window.innerHeight > window.innerWidth * 1.2;


        console.log("appPromoShown: " + appPromoShown)
        console.log("isMobileDevice: " + isMobileDevice)
        if (!appPromoShown && isMobileDevice) {
            setIsVisible(true);
        } else if (isMobileDevice && appPromoShown) {
            const shouldShowPromo = Math.random() <= 0.1; // 20% chance
            setIsVisible(shouldShowPromo);
        }
    }, []);

    const hidePromo = () => {
        setIsVisible(false);
        localStorage.setItem('appPromoShown', 'true'); // Mark promo as shown in loca1lStorage
    };

    return (
        isVisible && (
            <div className="app-promo">
                <img src={appPromo} alt="App Promo" />
                <div className='buttonsapp'>
                    <a className='button-sec' onClick={hidePromo}>Скрыть</a>
                    <a className='actionButton' href='https://play.google.com/store/apps/details?id=lol.animix.app'>Скачать на Android</a>
                </div>

            </div>
        )
    );
};

export default AppPromo;
