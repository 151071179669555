import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import profileIcon from './../assets/ic_profile.svg'; // Adjust the path as necessary
import settingsIcon from './../assets/ic_settings.svg';
import logoutIcon from './../assets/ic_switch.svg';
import './ProfilePopup.css'; // Import your CSS file for styling

const ProfilePopup = ({ visible }) => {
    return (
        <div className={`profile-popup ${visible ? "" : "gone"}`}>
            <ul>
                <li>
                    <Link to="/profile">
                        <img src={profileIcon} alt="Profile" className="icon" />
                        <span>Мой профиль</span>
                    </Link>
                </li>
                <li>
                    <Link to="/settings">
                        <img src={settingsIcon} alt="Settings" className="icon" />
                        <span>Настройки</span>
                    </Link>
                </li>
                <li>
                    <Link to="/login">
                        <img src={logoutIcon} alt="Logout" className="icon" />
                        <span>Сменить аккаунт</span>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default ProfilePopup;
