import React, { useEffect, useRef, useState } from 'react';
import { Player, PlayerOptions, loadImaSdk } from '@glomex/vast-ima-player';

const VastAdPlayer = () => {
  const mediaElementRef = useRef(null);
  const adContainerRef = useRef(null);
  const [selectedAdUrl, setSelectedAdUrl] = useState('https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ct%3Dskippablelinear&correlator=[timestamp]');
  
  useEffect(() => {
    let imaPlayer;

    const setupVastAdPlayer = async () => {
      const ima = await loadImaSdk();

      if (window.google && window.google.ima) {
        const adsRenderingSettings = new window.google.ima.AdsRenderingSettings();
        const playerOptions = new PlayerOptions();

        // Destroy the previous player instance if exists
        if (imaPlayer) {
          imaPlayer.destroy();
        }

        imaPlayer = new Player(
          ima, 
          mediaElementRef.current, 
          adContainerRef.current, 
          adsRenderingSettings, 
          playerOptions
        );

        window.google.ima.settings.setVpaidMode(window.google.ima.ImaSdkSettings.VpaidMode.INSECURE);
        window.google.ima.settings.setNumRedirects(3);

        imaPlayer.addEventListener('AdStarted', (event) => {
          console.log('Ad started:', event.detail.ad);
        });

        imaPlayer.addEventListener('AdProgress', () => {
          console.log('Ad timeupdate', imaPlayer.duration, imaPlayer.currentTime);
        });

        imaPlayer.addEventListener('timeupdate', () => {
          console.log('Content timeupdate', imaPlayer.duration, imaPlayer.currentTime);
        });

        mediaElementRef.current.muted = true;

        const playAdsRequest = new window.google.ima.AdsRequest();
        playAdsRequest.adTagUrl = selectedAdUrl;

        // Start the ad playback
        imaPlayer.playAds(playAdsRequest);
      } else {
        console.error('Google IMA SDK failed to load.');
      }
    };

    setupVastAdPlayer();

    // Clean up the player instance on unmount or before reinitializing
    return () => {
      if (imaPlayer) {
        imaPlayer.destroy();
      }
    };
  }, [selectedAdUrl]);  // Re-run effect when selectedAdUrl changes

  const handleAdSwitch = (event) => {
    setSelectedAdUrl(event.target.value);
  };

  return (
    <div>
      <div>
        {/* Dropdown to switch between different VAST ad URLs */}
        <label htmlFor="adSelect">Select Ad:</label>
        <select id="adSelect" onChange={handleAdSwitch} value={selectedAdUrl}>
          <option value="https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ct%3Dskippablelinear&correlator=[timestamp]">
            Google Sample Ad
          </option>
          <option value="https://glomex.github.io/vast-ima-player/linear-ad.xml">Glomex Linear Ad</option>
          <option value="https://exchange.buzzoola.com/ad/1276704">Buzzoola Ad</option>
        </select>
        <label>Не меняйте провайдера до того, как vast реклама закончится, иначе смены не</label>
      </div>

      {/* Media element where the content will be played */}
      <video ref={mediaElementRef} width="640" height="360" controls>
        <source src={"your-content-video.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Ad container where the ads will be rendered */}
      <div ref={adContainerRef} style={{ width: '640px', height: '360px' }}></div>
    </div>
  );
};

export default VastAdPlayer;
