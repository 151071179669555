import React, { useState, useEffect, useRef } from 'react';
import AnimeCardComponent from './animecard/AnimeCardComponent';
import mindIcon from '../assets/ic_mind.svg';
import arrowIcon from '../assets/ic_arrow_right.svg';
import YandexAdComponent from './YandexAdComponent';
import adBackgroundImage from '../assets/ad_placeholder.png';
import adAska from '../assets/ad_aska.png';
import adVpn from '../assets/ad_vpn.png';
import adHq from '../assets/ad_quality.png';
import adPop from '../assets/ad_pop.png';
import adRei from '../assets/ad_rei.png';
import AnimixPremiumPopup from './premium/AnimixPremiumPopup';
import { useNavigate, useParams } from 'react-router-dom';
// Utility function to get a random title from a list
const getRandomTitle = (titles) => {
    return titles[Math.floor(Math.random() * titles.length)];
};

const RecommendationsComponent = ({ recs }) => {
    // Define title lists for each category
    const categoryTitles = ["Категория", "Специально для Вас", "Выбор редакции"];
    const neuralTitles = ["Нейронные рекомендации", "Персональная рекомендация", "ИИ советует", "Специально для Вас"];
    const popularTitles = ["Популярные", "Тренды", "Лидеры просмотров"];
    const viralTitles = ["Вирусные", "Топ на этой неделе", "Много смотрят"];
    const ratingTitles = ["Лучший рейтинг", "Топ по оценкам", "Высоко оцененные"];
    const hqTitles = ["Аниме в 4К и 2К", "Высокое качество", "Эксклюзивно в 2К и 4К"];
    const [showSubscribePopup, setShowSubscribePopup] = useState(false);

    const [showAdBlock, setShowAdBlock] = useState(false); // State to control ad visibility

    const navigate = useNavigate();

    // State to store random titles for each section
    const [randomTitles, setRandomTitles] = useState({
        category: '',
        neural: '',
        popular: '',
        viral: '',
        rating: '',
        hq: '',
    });

    // State to track arrow visibility for each section
    const [arrowVisibility, setArrowVisibility] = useState({
        neuralLeft: false,
        neuralRight: true,
        popularLeft: false,
        popularRight: true,
        viralLeft: false,
        viralRight: true,
        ratingLeft: false,
        ratingRight: true,
        hqLeft: false,
        hqRight: true,
    });

    // Define ad objects with image, title, text, and link
    const ads = [
        {
            title: "Отключите рекламу",
            text: "Наслаждайтесь просмотром аниме без рекламы",
            image: adAska,
            link: "premium" // This will trigger the premium popup
        },
        {
            title: "Настройте рекомендации",
            text: "Получите персональные рекомендации, настроенные под ваши предпочтения.",
            image: adBackgroundImage,
            link: "premium" // This will trigger the premium popup
        },
        {
            title: "Наш VPN",
            text: "Высокие скорости и соврмеменные протоколы",
            image: adVpn,
            link: "https://play.google.com/store/apps/details?id=ru.oig.etyvpn" // This will openч in a new tab
        },
        {
            title: "Смотрите аниме в 4К и 2К",
            text: "Невероятная чёткость специально для вас, эксклюзивно на animix",
            image: adHq,
            link: "premium" // This will openч in a new tab
        },
        {
            title: "На ПК - сайт, на телефон - прилка",
            text: "Удобный плеер, более стабильная и быстрая работа",
            image: adPop,
            link: "https://play.google.com/store/apps/details?id=lol.animix.app" // This will open in a new tab
        },
        {
            title: "Новости, конкурсы и аниме-чатик",
            text: "Всё это про наш Telegram. Уютное место для людей, которые любят аниме",
            image: adRei,
            link: "https://t.me/animixlib" // This will open in a new tab
        }
    ];

    // Utility function to get a random item from an array
    const getRandomItem = (items) => {
        return items[Math.floor(Math.random() * items.length)];
    };

    const [randomAd, setRandomAd] = useState(null);

    useEffect(() => {
        // Set a random ad on mount
        setRandomAd(getRandomItem(ads));
    }, []); // Empty dependency array ensures this effect runs only once

    const handleAdClick = (ad) => {
        if (ad.link === "premium") {
            setShowSubscribePopup(true); // Show premium popup
        } else {
            window.open(ad.link, "_blank"); // Open external link in new tab
        }
    };

    // Refs to access the scrollable containers
    const neuralRef = useRef(null);
    const popularRef = useRef(null);
    const viralRef = useRef(null);
    const ratingRef = useRef(null);
    const hqRef = useRef(null);

    // Generate random titles once when component mounts
    useEffect(() => {
        setRandomTitles({
            category: getRandomTitle(categoryTitles),
            neural: getRandomTitle(neuralTitles),
            popular: getRandomTitle(popularTitles),
            viral: getRandomTitle(viralTitles),
            rating: getRandomTitle(ratingTitles),
            hq: getRandomTitle(hqTitles),
        });

        // Random chance to show ad block (e.g., 30% chance)
        if (Math.random() < 0.3 && localStorage.getItem("subscribed") != "true") {
            setShowAdBlock(true);
        }
    }, []); // Empty dependency array ensures this effect runs only once

    // Function to handle scrolling and arrow visibility
    const scroll = (ref, direction, refName) => {
        if (ref.current) {
            const scrollDistance = direction === 'left' ? -500 : 500;
            ref.current.scrollBy({
                left: scrollDistance,
                behavior: 'smooth',
            });
            setTimeout(() => checkScroll(ref, refName), 300); // Check scroll after animation
        }
    };

    // Function to check if arrows should be visible
    const checkScroll = (ref, refName) => {
        if (ref.current) {
            const { scrollLeft, scrollWidth, clientWidth } = ref.current;

            setArrowVisibility((prevState) => ({
                ...prevState,
                [`${refName}Left`]: scrollLeft > 0,
                [`${refName}Right`]: scrollLeft + clientWidth < scrollWidth,
            }));
        }
    };

    // Initial scroll check on mount
    useEffect(() => {
        checkScroll(neuralRef, 'neural');
        checkScroll(popularRef, 'popular');
        checkScroll(viralRef, 'viral');
        checkScroll(ratingRef, 'rating');
        checkScroll(hqRef, 'hq');
    }, [recs]);

    return (
        <div>
            {/* Handle CATEGORY */}
            {recs.CATEGORY && (
                <a className="container-wrapper" key={recs.CATEGORY.id}>
                    <label  onClick={() => {navigate(`/category/${recs.CATEGORY.id}`)}}>Категория</label>
                    <h1 className='category-title'>{recs.CATEGORY.name}</h1>
                    <div className="anime-line-container-padding">
                        <div className="anime-line-container">
                            {recs.CATEGORY.animeList.map((anime) => (
                                <AnimeCardComponent key={anime.id} anime={anime} isInteractive={true} />
                            ))}
                        </div>
                        <a  onClick={() => {navigate(`/category/${recs.CATEGORY.id}`)}} style={{cursor: "pointer"}} className="view-all-button">Посмотреть все</a>
                    </div>
                </a>
            )}

            {/* Handle NEURAL */}
            {recs.NEURAL && (
                <div className="container-wrapper">
                    <label className='tip-label'>Оставляйте позитивные оценки, чтобы улучшить подборку</label>
                    <h1 className='anime-title tip'> <img className='mind-icon' src={mindIcon} /> {randomTitles.neural}</h1>
                    <div className="anime-line-container-padding">
                        {arrowVisibility.neuralLeft && (
                            <button className="scroll-button left" onClick={() => scroll(neuralRef, 'left', 'neural')}>
                                <img src={arrowIcon} />
                            </button>
                        )}
                        <div className="anime-line-container" ref={neuralRef}>
                            {recs.NEURAL.map((anime) => (
                                <AnimeCardComponent key={anime.id} anime={anime} isInteractive={true} />
                            ))}
                        </div>
                        {arrowVisibility.neuralRight && (
                            <button className="scroll-button right" onClick={() => scroll(neuralRef, 'right', 'neural')}>
                                <img src={arrowIcon} />
                            </button>
                        )}
                    </div>
                </div>
            )}

            {recs.HQ && (
                <div className="container-wrapper">
                    <h1 className='anime-title'>{randomTitles.hq}</h1>
                    <div className="anime-line-container-padding">
                        {arrowVisibility.hqLeft && (
                            <button className="scroll-button left" onClick={() => scroll(hqRef, 'left', 'hq')}>
                                <img src={arrowIcon} />
                            </button>
                        )}
                        <div className="anime-line-container" ref={hqRef}>
                            {recs.HQ.map((anime) => (
                                <AnimeCardComponent key={anime.id} anime={anime} isInteractive={true} />
                            ))}
                        </div>
                        {arrowVisibility.hqRight && (
                            <button className="scroll-button right" onClick={() => scroll(hqRef, 'right', 'hq')}>
                                <img src={arrowIcon} />
                            </button>
                        )}
                    </div>
                </div>
            )}

            {/* Handle POPULAR */}
            {recs.POPULAR && (
                <div className="container-wrapper">
                    <h1 className='anime-title'>{randomTitles.popular}</h1>
                    <div className="anime-line-container-padding">
                        {arrowVisibility.popularLeft && (
                            <button className="scroll-button left" onClick={() => scroll(popularRef, 'left', 'popular')}>
                                <img src={arrowIcon} />
                            </button>
                        )}
                        <div className="anime-line-container" ref={popularRef}>
                            {recs.POPULAR.map((anime) => (
                                <AnimeCardComponent key={anime.id} anime={anime} isInteractive={true} />
                            ))}
                        </div>
                        {arrowVisibility.popularRight && (
                            <button className="scroll-button right" onClick={() => scroll(popularRef, 'right', 'popular')}>
                                <img src={arrowIcon} />
                            </button>
                        )}
                    </div>
                </div>
            )}
            {recs.REVIEW && recs.REVIEW.reviewEntity != null ? (
                <div className="review-container">
                    <div className="review-header">


                        <AnimeCardComponent key={recs.REVIEW.animeEntity.id} anime={recs.REVIEW.animeEntity} isInteractive={true} />

                        <div className="review-body">
                            <div className="anime-stars">
                                {Array(recs.REVIEW.reviewEntity.stars).fill('★')}
                            </div>
                            <h3 className="rec-review-message">{recs.REVIEW.reviewEntity.message}</h3>
                            <p className="rec-anime-name">{recs.REVIEW.animeEntity.name}</p>
                            <label>Отзыв пользователя</label>
                        </div>
                    </div>

                </div>
            ) : ''}


            {/* Handle VIRAL */}
            {recs.VIRAL && (
                <div className="container-wrapper">
                    <h1 className='anime-title'>{randomTitles.viral}</h1>
                    <div className="anime-line-container-padding">
                        {arrowVisibility.viralLeft && (
                            <button className="scroll-button left" onClick={() => scroll(viralRef, 'left', 'viral')}>
                                <img src={arrowIcon} />
                            </button>
                        )}
                        <div className="anime-line-container" ref={viralRef}>
                            {recs.VIRAL.map((anime) => (
                                <AnimeCardComponent key={anime.id} anime={anime} isInteractive={true} />
                            ))}
                        </div>
                        {arrowVisibility.viralRight && (
                            <button className="scroll-button right" onClick={() => scroll(viralRef, 'right', 'viral')}>
                                <img src={arrowIcon} />
                            </button>
                        )}
                    </div>
                </div>
            )}

            {/* Handle RATING */}
            {recs.RATING && (
                <div className="container-wrapper">
                    <h1 className='anime-title'>{randomTitles.rating}</h1>
                    <div className="anime-line-container-padding">
                        {arrowVisibility.ratingLeft && (
                            <button className="scroll-button left" onClick={() => scroll(ratingRef, 'left', 'rating')}>
                                <img src={arrowIcon} />
                            </button>
                        )}
                        <div className="anime-line-container" ref={ratingRef}>
                            {recs.RATING.map((anime) => (
                                <AnimeCardComponent key={anime.id} anime={anime} isInteractive={true} />
                            ))}
                        </div>
                        {arrowVisibility.ratingRight && (
                            <button className="scroll-button right" onClick={() => scroll(ratingRef, 'right', 'rating')}>
                                <img src={arrowIcon} />
                            </button>
                        )}
                    </div>
                </div>
            )}

            <div className="container-wrapper"> <YandexAdComponent blockId="R-A-8131234-2" /> </div>

            {showAdBlock && randomAd && (
                <div className="full-width-da-block">
                    <img src={randomAd.image} alt="Ad Background" />
                    <h2 className="da-title">{randomAd.title}</h2>
                    <p className="da-text">{randomAd.text}</p>
                    <button className="da-button" onClick={() => handleAdClick(randomAd)}>
                        Узнать больше
                    </button>
                </div>
            )}

            {showSubscribePopup && (
                <AnimixPremiumPopup onClose={() => setShowSubscribePopup(false)} />
            )}
        </div>
    );
};

export default RecommendationsComponent;
