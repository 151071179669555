import React, {useEffect, useRef, useState} from 'react';
import './NotificationPopup.css'; // Import your CSS file for styling
import ApiService from '../services/ApiService';

const NotificationPopup = ({visible, onClose}) => {
    const [notifications, setNotifications] = useState([{
        "id": -1,
        "userId": 1,
        "date": (new Date()).getTime(),
        "message": "Привет! Тут будут Ваши уведомления",
        "url": null,
        "type": "AD"
    }]);
    const [page, setPage] = useState(0);

    let fetched = false;

    useEffect(() => {
        const fetchNotifications = async () => {
            if (fetched) return;
            fetched = true;
            try {
                const data = await ApiService.getNotifications(page);
                if (data.data.length != 0)
                    setNotifications(data.data);
            } catch (error) {
              //  console.error('Failed to fetch notifications:', error);
            }
        };

        fetchNotifications();
    }, [page]);

    if (visible && notifications.length > 0)
        localStorage.setItem("seenNotId", notifications[0].id)

    const [isVisible, setIsVisible] = useState(true);
    const popupRef = useRef(null);

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setIsVisible(false);
            if (onClose) onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (!isVisible) return null;

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const options = {year: 'numeric', month: 'long', day: 'numeric'};
        const dateString = date.toLocaleDateString('ru-RU', options);
        const timeString = date.toLocaleTimeString('ru-RU', {hour: '2-digit', minute: '2-digit'});
        return `${dateString} в ${timeString}`;
    };

    return (
        <div className={`not-popup ${visible ? "" : "gone"}`} ref={popupRef}>
            <ul>
                {notifications.map((notification) => (
                    <li key={notification.date}>
                        <a href={notification.url} className="notification">
                            <div className="notification-message">{notification.message}</div>
                            <div className="notification-date">{formatDate(notification.date)}</div>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default NotificationPopup;
