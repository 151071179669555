import React, { useState, useEffect, useRef } from 'react';
import './../../App.css'; // Assuming you have a CSS file for styling
import './AnimePage.css'; // Assuming you have a CSS file for styling
import { useParams, useNavigate } from 'react-router-dom';

// Import logo.svg from the assets folder
import Header from '../../components/Header';
import PlayIcon from '../../assets/ic_play.svg';
import NotifyIcon from '../../assets/ic_notification.svg';
import Footer from '../../components/Footer';
import video from './../../assets/output.mp4';
import AnimeCardComponent from '../../components/animecard/AnimeCardComponent';
import infoIcon from '../../assets/ic_info.svg';
import ApiService from '../../services/ApiService';

import SeasonComponent from '../../components/SeasonComponents';
import LeaveReviewComponent from '../../components/LeaveReviewComponent';
import ReviewComponent from '../../components/ReviewComponent';
import YandexAdComponent from '../../components/YandexAdComponent';
import helpIcon from '../../assets/help.jpg';
import eyeIcon from '../../assets/ic_eye.svg';
import AppPromo from '../../components/AppPromo';

const AnimePage = () => {
    const { id } = useParams();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [anime, setAnime] = useState([]);
    const [seriesData, setSeriesData] = useState(null);
    const [url, setUrl] = useState(video);
    const [hasFirst, setHasFirst] = useState(false);

    const videoRef = useRef(null);
    const navigate = useNavigate();
    const [seriesCount, setSeriesCount] = useState(0);
    const [collections, setCollections] = useState(null);
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [showLoadReviewsButton, setShowLoadReviewsButton] = useState(true);
    const [reviewsPage, setReviewsPage] = useState(0);
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [unavailableReason, setUnavailableReason] = useState("Контент недоступен для просмотра для зрителей из всего мира. Возможно, мы проводим временные работы с сериями этого аниме");
    const [reviews, setReviews] = useState([]);
    const [imageLoaded, setImageLoaded] = useState(false);


    // New flag to track if data has been fetched
    const hasFetched = useRef(false);

    useEffect(() => {
        // Scroll to the top of the page when the component is rendered
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (seriesData == null) return;
        let count = 0;
        Object.values(seriesData).forEach(season => {
            count += season.length;
        });
        setSeriesCount(count);
    }, [seriesData]);

    const fetchData = async () => {
        const blacklistedData = await ApiService.isBlacklisted(id);

        if (blacklistedData.data == true) {
            setIsUnavailable(true)
            setUnavailableReason("Недоступно на территории Вашего региона (Россия, Беларусь или другая страна) по запросу правообладателя ")


            return
        }
        const animeData = await ApiService.getAnime(id);
        try {
            if (animeData.type === "success") {
                setAnime(animeData.data);
                if (localStorage.getItem("isAdult") == null) {
                    setIsPopupOpen(animeData.data.pg >= 18);
                }
                document.title = animeData.data.name + " на animix";
            } else {
                setIsUnavailable(true);
                return;
            }
        } catch (error) {
            console.error('Error fetching anime list:', error);
        }

        try {
            const data = await ApiService.getSeries(id);
            setSeriesData(data.data);
            fetchReviews(id, 0);
            if (data.data["1"].length > 0) {
                setHasFirst(true);
                // const animeData = await ApiService.getWatchSession(data.data["1"][0].id);

                if (animeData.type === "success") {
                    //    setUrl("https://api.animix.lol/anime/watch?session=" + animeData.data);

                    // setHasFirst(true);
                }
            }



        } catch (error) {
            console.error('Error fetching series data:', error);
        }

        try {
            fetchCollections();

        } catch (error) {
            console.error('Error fetching collections:', error);
        }

    };

    const fetchCollections = async () => {
        try {
            const profileId = localStorage.getItem("profileId");
            const collectionsData = await ApiService.getCollections(profileId);

            collectionsData.data.forEach(element => {
                const animeId = parseInt(id);
                if (element.animeIds.includes(animeId)) {
                    setSelectedCollection(element.id);
                }
            });
            setCollections(collectionsData.data);
        } catch (error) {
            console.error('Error fetching collections:', error);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            setScrollPosition(position);
            console.log(position)
        };

        window.addEventListener('scroll', handleScroll, { passive: true });
        if (!hasFetched.current) {
            fetchData();
            hasFetched.current = true; // Mark data as fetched
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const handleImageLoad = () => {
        setImageLoaded(true);
    };



    const handleWatchButton = () => {
        navigate(`/watch/${anime.id}/${seriesData["1"][0].id}`);
    };

    const handleLoad = () => {
        if (videoRef.current != null) {
            if (videoRef.current.currentTime < 50) {
                videoRef.current.currentTime = 50;
            }
        }
    };


    const handleSubscribeToggle = async (e) => {
        const data = await ApiService.setSubscribed(!anime.subscribed, anime.id);
        fetchData();
    };

    const handleStatusChange = async (e) => {
        const data = await ApiService.addCollection(e.target.value, anime.id);
        fetchCollections();
    };

    const fetchReviews = async (animeId, page) => {
        try {
            const reviewsData = await ApiService.getReviews(animeId, page);

            if (page === 0) {
                setReviews(reviewsData.data);
            } else {
                setReviews(reviews.concat(reviewsData.data));
            }

            if (reviewsData.data.length < 5) {
                setShowLoadReviewsButton(false);
            }
        } catch (error) {
            console.error('Error fetching reviews:', error);
        }
    };

    function formatWatchCount(num) {
        if (num >= 10000000) {
            return (num / 10000000).toFixed(2) + "Cr"; // Crores (1Cr = 10 million)
        } else if (num >= 1000000) {
            return (num / 1000000).toFixed(2) + "M"; // Millions
        } else if (num >= 100000) {
            return (num / 100000).toFixed(1) + "L"; // Lakhs (1L = 100,000)
        } else if (num >= 1000) {
            return (num / 1000).toFixed(2) + "K"; // Thousands
        }
        return num; // Less than 1000, return as-is
    }

    return (
        <div>
            <Header scrolled={scrollPosition > 0} />

            {isUnavailable ? (
                <div className='content unavailable-con'>
                    <img src={helpIcon} />
                    <h2>Это аниме недоступно</h2>
                    <p>Причина: {unavailableReason}</p>
                </div>
            ) : (
                <div>
                    <div className="anime-info-content">
                        {/* <video src={url} type="video/mp4" ref={videoRef} onPlay={handleLoad} className="backgroundVideo" autoPlay loop muted /> */}
                        <img
                            src={`https://api.animix.lol/anime/getThumb/${anime.id}_thumb.png`}
                            alt={anime.name}
                            className={`blud-back ${imageLoaded ? 'loaded' : ''}`}
                            onLoad={handleImageLoad}
                        />
                        <div className='anime-info-container'>

                            <AnimeCardComponent anime={anime} />
                            <div className='anime-info-div'>
                                <div className='center-content'>
                                    <p className={`pg-anime-text ${anime.pg == 18 ? "adult" : ""}`}>{anime.pg}+</p>
                                 
                                    {anime.hq ?  <p className={`pg-anime-text hiq`}>Высокое качество</p> : ''}
                                    {anime.viral > 10 ?  <p className={`pg-anime-text viral`}>Популярность {anime.viral}</p> : ''}
                                </div>

                                <h1 className='anime-name'>{anime.name}</h1>

                                <p className='anime-text'>Выпуск: {anime.productionDates}<br />

                                    Жанр: {anime.genres}</p>

                                <div className='anime-actions-con'>
                                    {hasFirst ? <button className='actionButton playButton' onClick={handleWatchButton}>  <img src={PlayIcon} alt="Play Icon" className="playIcon" />  {anime.movie ? "Смотреть" : "1 серия"}</button> : ""}
                                    <button className='actionButton subscribeButton' onClick={handleSubscribeToggle}>  <img src={NotifyIcon} alt="Play Icon" className="playIcon" />{anime.subscribed ? "Отписаться" : "Подписаться"}</button>
                                    {localStorage.getItem("profileId") == null ? "" :
                                        <div>

                                            <select id="seasonFilter" value={selectedCollection} onChange={handleStatusChange}>
                                                <option key={-1} value={-1}>Не выбрано</option>
                                                {collections != null ? collections.map(collection => (
                                                    <option key={collection.id} value={collection.id}>{collection.name}</option>
                                                )) : ""}
                                            </select>
                                        </div>
                                    }

                                    <div className="watch-count-con">
                                        <img src={eyeIcon}/>
                                        <p>{formatWatchCount(anime.watchCount)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='anime-half-con'>
                        <div className="anime-half-desc">
                            
                                {anime.announce != null ? anime.announce.length > 0 ?  <div className='announce'>
                                <img src={infoIcon} className='profile-card-icon'></img>
                                <a>{anime.announce}</a></div> : "" : ""}

                           
                            <h1>О чём</h1>
                            <div className="anime-text-small-container">
                                <p className={`anime-text-small-desc ${isDescriptionExpanded ? 'expanded' : ''}`}>
                                    {anime.description}
                                </p>
                                {!isDescriptionExpanded && (
                                    <button
                                        className="expand-button"
                                        onClick={() => setIsDescriptionExpanded(true)}
                                    >
                                        Показать полностью
                                    </button>
                                )}
                            </div>
                            <h1>Отзывы</h1>
                            <LeaveReviewComponent animeId={anime.id} onSubmit={() => { fetchReviews(id, 0) }} />
                            <div className="reviews">
                                {reviews.length !== 0 ? reviews.map((review) => (
                                    <ReviewComponent key={review.id} review={review} />
                                )) : ""}
                            </div>
                            {showLoadReviewsButton ? <h3 className='button-sec' style={{ textAlign: 'center', marginTop: 10 }} onClick={() => {
                                setReviewsPage(reviewsPage + 1);
                                fetchReviews(id, reviewsPage + 1);
                            }}>Загрузить ещё</h3> : ""}
                        </div>
                        <div className="anime-half">

                            {localStorage.getItem("subscribed") == true ? "" : <YandexAdComponent blockId="R-A-8131234-2" />}

                            <h1>{seriesData != null ? seriesCount === 1 ? "Смотреть" : "Смотреть серии" : "Смотреть серии"}</h1>
                            {seriesData ? <SeasonComponent seriesData={seriesData} animeData={anime} /> : <p>Загружаем серии...</p>}
                        </div>
                    </div>
                    {isPopupOpen && (
                        <div className="popup-container">
                            <div className="popup" onClick={() => setIsPopupOpen(false)}>
                                <h2>Вам есть 18 лет?</h2>
                                <p>Это произведение предназначено только для взрослой аудитории</p>
                                <button onClick={() => {
                                    if (localStorage.getItem("profileId") != null) {
                                        localStorage.setItem("isAdult", true);
                                    }
                                    setIsPopupOpen(false);
                                }}>Да, Больше не показывать</button>
                                <button className='button-sec' onClick={() => navigate('/')}>Нет</button>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <AppPromo/>
            <Footer />
        </div>
    );
};

export default AnimePage;
