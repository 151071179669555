import axios from 'axios';
import ApiService from './ApiService';

const API_URL = 'https://api.animix.lol';
const CDN_URL = 'https://cdn.animix.lol';


const ContentService = {

    fetchVideo: async (series) => {

        try {


            const optimalServerData = await ApiService.getOptimalServer(series.privateId);
            console.log("obtaining watch url")
            if (optimalServerData.data === "no-cache") {
                return "https://cdn.animix.lol/content/directWatch?privateId=" + series.privateId;
            }
            else {
                const animeData = await ApiService.getWatchSession(series.privateId, optimalServerData.data);
                return optimalServerData.data + "content/watch/" + animeData.data;
            }


        } catch (error) {
            console.error('Error fetching anime list:', error);
        }
    }

}
export default ContentService;
