import React, { useState, useEffect } from 'react';
import './AdBlockWarn.css';
import { useDetectAdBlock } from "adblock-detect-react";
import logo from '../../assets/logo.svg';
import AnimixPremiumPopup from '../premium/AnimixPremiumPopup';
import { DetectAdblock } from '@scthakuri/adblock-detector';

const AdBlockWarn = ({forceShow = false}) => {
    const [isVisible, setIsVisible] = useState(forceShow);
    const [timeFirst, setTimeFirst] = useState(0);
    const adBlockDetected = useDetectAdBlock();
    const [adDetected, setAdDetected] = useState(false);
    const [showSubscribePopup, setShowSubscribePopup] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState(8);
    const [closeCount, setCloseCount] = useState(localStorage.getItem("closeCount")); // Track number of times the close icon is shown
    const [showCloseIcon, setShowCloseIcon] = useState(false); // Show close icon after 8 sec
    const [startDetect, setStartDetect] = useState(true);

    useEffect(() => {
        const checkAdBlock = () => {
            if (Date.now() - timeFirst > 10000 && timeFirst !== 0) return;
            if (timeFirst === 0) {
                setTimeFirst(Date.now());
            }

            if (adBlockDetected) {
                console.log("detected #1");
            }
            if (adBlockDetected && localStorage.getItem("subscribed") !== "true") {
                setIsVisible(true);
            }
        };

        if (localStorage.getItem("closeCount") == null) {
            setCloseCount(0)
        }

        checkAdBlock();
    }, [adBlockDetected, timeFirst]);



    // Timer countdown effect
    useEffect(() => {
        if (isVisible && secondsLeft > 0) {
            const timer = setInterval(() => {
                setSecondsLeft((prev) => prev - 1);
            }, 1000);

            return () => clearInterval(timer);
        } else if (secondsLeft === 0) {
            if (closeCount < 140) { // Show close icon for the first 10 times
                setShowCloseIcon(true);
            }
        }
    }, [isVisible, secondsLeft, closeCount]);


    const handleClose = () => {
        localStorage.setItem("closeCount", closeCount + 1)
        setIsVisible(false);
        setCloseCount(closeCount + 1); // Increment close count
        setSecondsLeft(8); // Reset the timer for the next popup
        setShowCloseIcon(false); // Hide the close icon for the next time
    };

    return (
        isVisible && (
            <div className="ad-block-popup-con">
                <div className='popup'>
                    <img src={logo} alt="App Promo" />
                    <h3>Блокировщики рекламы нарушают условия использования animix</h3>
                    <label>Благодаря рекламе мы можем обеспечивать просмотр тысяч аниме для десятков тысяч человек. Если Вы не хотите смотреть рекламу, Вы можете подписаться на наш сервис.</label>
                    <label style={{ marginTop: 20 }}>Статус премиума даст много преимуществ помимо отключения рекламы</label>
                    <div className='horizontal-container2'>
                        <a className='actionButton' onClick={() => setShowSubscribePopup(true)}>Отключить рекламу</a>
                        <a href='https://lumpics.ru/how-to-disable-ad-block-in-pc/'>Как отключить блокировщик?</a>
                    </div>

                    {/* Timer Display */}
                    <div className="timer">
                        {secondsLeft > 0 ? `${secondsLeft}` : ''}
                    </div>

                    {/* Close button after 8 seconds and for the first 10 times */}
                    {showCloseIcon && closeCount < 10 && (
                        <div className="close-icon" onClick={handleClose}>
                            ✖
                        </div>
                    )}
                </div>

                {showSubscribePopup && (
                    <AnimixPremiumPopup onClose={() => setShowSubscribePopup(false)} />
                )}
            </div>
        )
    );
};

export default AdBlockWarn;
