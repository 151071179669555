import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import logo from './../assets/logo.svg';
import defaultPlaceholder from './../assets/placeholder.png';
import ApiService from './../services/ApiService';
import ErrorCodes from '../services/ErrorCodes';
import { Turnstile } from "@marsidev/react-turnstile"; // Import Turnstile

const RegisterPage = () => {
    const [placeholderImage, setPlaceholderImage] = useState(defaultPlaceholder);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [hasProblem, setHasProblem] = useState(false);
    const [problemText, setProblemText] = useState('');
    const [isAgreed, setIsAgreed] = useState(false); // Add checkbox state
    const navigate = useNavigate();
    const captchaRef = useRef(null); // Create a ref for Turnstile component
    const [captchaKey, setCaptchaKey] = useState(null);

    useEffect(() => {
        const getRandomNumber = (min, max) => {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };
        document.title = 'Регистрация в animix';
        const randomImageNumber = getRandomNumber(1, 3);

        try {
            const randomImage = require(`./../assets/back_${randomImageNumber}.png`);
            setPlaceholderImage(randomImage);
        } catch (error) {
            console.error('Error loading random placeholder image:', error);
        }
    }, []);

    const handleCreateAccount = async (event) => {
        event.preventDefault();
        if (!isAgreed) {
            setHasProblem(true);
            setProblemText('Вы должны согласиться с условиями политики конфиденциальности и публичной оферты');
            resetCaptcha(); // Reset Turnstile if error
            return;
        }

        if (password !== confirmPassword) {
            setHasProblem(true);
            setProblemText('Пароли не совпадают');
            resetCaptcha(); // Reset Turnstile if error
            return;
        }

        if (password.length < 8) {
            setHasProblem(true);
            setProblemText('Пароль слишком короткий');
            resetCaptcha(); // Reset Turnstile if error
            return;
        }

        try {
            setHasProblem(false);
            const response = await ApiService.registerUser(login, password, 121212, captchaKey);

            if (response["type"] === "success") {
                localStorage.setItem('sessionKey', response.data.key);
                navigate('/');
            } else {
                setHasProblem(true);
                setProblemText(ErrorCodes.getText(response));
                resetCaptcha(); // Reset Turnstile if registration fails
            }
        } catch (error) {
            console.error('Error registering user:', error.message);
            setHasProblem(true);
            setProblemText("Неизвестная ошибка. ", error.message);
            resetCaptcha(); // Reset Turnstile in case of an unknown error
        }
    };

    const handleLogin = () => {
        navigate('/login');
    };

    const onCaptchaSuccess = (token) => {
        setCaptchaKey(token);  // Set the Turnstile token
    };

    const resetCaptcha = () => {
        if (captchaRef.current) {
            captchaRef.current.reset();  // Reset Turnstile captcha
        }
    };

    return (
        <div className="login-page">
            <div className="left-section">
                <div className="placeholder-container">
                    <img src={placeholderImage} alt="Placeholder" className="placeholder-image" />
                </div>
            </div>

            <div className="right-section">
                <a href="/">
                    <img className="login-logo" src={logo} alt="Logo" />
                </a>
                <form className="login-form" onSubmit={handleCreateAccount}>
                    {hasProblem && <p className="banner">{problemText}</p>}
                    <input type="text" placeholder="Логин" value={login} onChange={(e) => setLogin(e.target.value)} />
                    <input type="password" placeholder="Пароль" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <input type="password" placeholder="Повтор" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                    
                    {/* Turnstile widget with ref */}
                    <Turnstile
                        siteKey="0x4AAAAAAAyEJI4rzy_Hz2cC"
                        onSuccess={onCaptchaSuccess}
                        className="turnstile-widget"
                        ref={captchaRef} // Attach ref to Turnstile
                    />

                    {/* Checkbox for terms and conditions */}
                    <div className="checkbox-container">
                        <input
                            type="checkbox"
                            id="terms-checkbox"
                            checked={isAgreed}
                            onChange={(e) => setIsAgreed(e.target.checked)}
                        />
                        <label htmlFor="terms-checkbox">
                            Согласен(-на) с политикой конфиденциальности, офертой
                        </label>
                    </div>
                    
                    <button type="submit" className='button-login'>Создать аккаунт</button>
                    <button className='button-sec login' onClick={handleLogin}>Войти</button>
                    <p className="privacy">Используя сайт, вы соглашаетесь с нашей политикой конфиденциальности</p>
                </form>
            </div>
        </div>
    );
};

export default RegisterPage;
