import React, { useEffect, useRef } from 'react';

const YandexAdComponent = ({ blockId }) => {
  // Generate a unique ID for each instance of the ad component
  const uniqueId = useRef(`yandex_rtb_${blockId}_${Math.random().toString(36).substr(2, 9)}`);

  useEffect(() => {
    const renderYandexAd = () => {
      if (localStorage.getItem("subscribed") === "true") {
        console.log("User is subscribed, no ads will be rendered.");
        return;
      }

      if (window.Ya && window.Ya.Context && window.Ya.Context.AdvManager) {
        console.log("Rendering Yandex ad...");
        window.Ya.Context.AdvManager.render({
          blockId: blockId,
          renderTo: uniqueId.current,  // Use the unique ID generated by useRef
        });
      } else {
        console.log("Yandex Ad Manager is not available yet.");
      }
    };

    const loadYandexScript = () => {
      if (!window.Ya || !window.Ya.Context) {
        console.log("Loading Yandex ad script...");
        const script = document.createElement('script');
        script.src = 'https://yandex.ru/ads/system/context.js';
        script.async = true;
        script.onload = () => {
          console.log("Yandex ad script loaded.");
          renderYandexAd();  // Render the ad once the script is loaded
        };
        document.head.appendChild(script);
      } else {
        console.log("Yandex script already loaded, rendering ad immediately.");
        renderYandexAd();
      }
    };

    if (localStorage.getItem("subscribed") === "true") {
      console.log("User is subscribed, no need to load ads.");
      return;
    }

    loadYandexScript();
  }, [blockId]);

  return (
    <div>
      {localStorage.getItem("subscribed") === "true" ? null : (
        <div id={uniqueId.current} style={{ margin: '20px 0' }}></div>
      )}
    </div>
  );
};

export default YandexAdComponent;
