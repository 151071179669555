import React, { useEffect, useRef, useState } from 'react';
import homeVideo from '../../assets/home.mp4';

const AdFoxInstreamTest2 = () => {
    const videoRef = useRef(null);
    const adContainerRef = useRef(null);
    const [adFoxParameters, setAdFoxParameters] = useState({
        ownerId: '264109',
        params: {
            p1: 'deceb',  // Pre-Roll test params
            p2: 'jdut',
        },
    });

    // Function to toggle adFox parameters
    const toggleAdFoxParams = () => {
        setAdFoxParameters((prevParams) =>
            prevParams.ownerId === '264109'
                ? {
                    ownerId: '270901',
                    params: {
                        p1: 'cwdyt',
                        p2: 'hiuy',
                    },
                }
                : {
                    ownerId: '264109',
                    params: {
                        p1: 'deceb',
                        p2: 'jdut',
                    },
                }
        );
    };

    var adfoxBiddersMap = {
        "betweenDigital": "812957",
        "sape": "2370535",
        "adriver": "2402354",
        "umg": "3046749",
        "mediasniper": "3047098",
        "solta": "3047102",
        "otclick": "3168175"
      };
  
  
      var adUnits = [
        {
          "code": "ety-cloud-player",
          "codeType": "instream",
          "bids": [
            {
              "bidder": "betweenDigital",
              "params": {
                "placementId": "4792760"
              },
              "adBreakTypes": [
                "preroll",
                "midroll",
                "postroll"
              ]
            },
            {
              "bidder": "sape",
              "params": {
                "placementId": "908538"
              },
              "adBreakTypes": [
                "preroll",
                "midroll",
                "postroll"
              ]
            },
            {
              "bidder": "adriver",
              "params": {
                "placementId": "79:animix.lol_instream"
              },
              "adBreakTypes": [
                "preroll",
                "midroll",
                "postroll"
              ]
            },
            {
              "bidder": "umg",
              "params": {
                "placementId": "8696"
              },
              "adBreakTypes": [
                "preroll",
                "midroll",
                "postroll"
              ]
            },
            {
              "bidder": "mediasniper",
              "params": {
                "placementId": "16000"
              },
              "adBreakTypes": [
                "preroll",
                "midroll",
                "postroll"
              ]
            },
            {
              "bidder": "solta",
              "params": {
                "placementId": "86-Ut9Bx"
              },
              "adBreakTypes": [
                "preroll",
                "midroll",
                "postroll"
              ]
            },
            {
              "bidder": "otclick",
              "params": {
                "placementId": "7644"
              },
              "adBreakTypes": [
                "preroll",
                "midroll",
                "postroll"
              ]
            }
          ]
        }
      ];
  
  
      var userTimeout = 1800;
  
  
      window.YaHeaderBiddingSettings = {
        biddersMap: adfoxBiddersMap,
        adUnits: adUnits,
        timeout: userTimeout,
      };

    useEffect(() => {
        // Load the ADFOX SDK
        const script = document.createElement('script');
        script.src = 'https://yandex.ru/ads/system/adsdk.js';
        script.async = true;
        script.onload = () => initializeAd();
        document.head.appendChild(script);

        const initializeAd = () => {
            const config = {
                adFoxParameters: {
                    ownerId: adFoxParameters.ownerId,
                    params: adFoxParameters.params,
                },
            };

            if (window.ya && window.ya.videoAd) {
                window.ya.videoAd
                    .play({
                        videoSlot: videoRef.current, // HTMLVideoElement
                        slot: adContainerRef.current, // Element to show ad controls
                        config: config,
                    })
                    .then(() => {
                        console.log('Ad playback finished');
                    })
                    .catch((err) => {
                        console.error('Ad failed to load:', err);
                    });
            }
        };
    }, [adFoxParameters]); // Re-run the effect when adFoxParameters change

    return (
        <div>
            <h1>ADFOX Instream Video Test with Toggle</h1>
            <button onClick={toggleAdFoxParams}>Toggle AdFox Parameters</button>
            <p>Current Owner ID: {adFoxParameters.ownerId}</p>
            <div ref={adContainerRef} style={{ width: '640px', height: '360px', position: 'relative' }}>
                <video
                    ref={videoRef}
                    controls
                    style={{ width: '100%', height: '100%' }}
                    id="ety-cloud-player"
                    type="video/mp4"
                    src={homeVideo}
                >
                    Your browser does not support HTML5 video.
                </video>
            </div>
        </div>
    );
};

export default AdFoxInstreamTest2;
