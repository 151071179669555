import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './AdminPage.css';
import ApiService from '../../services/ApiService';


const AdminPage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [animeList, setAnimeList] = useState([]);
    const [selectedAnime, setSelectedAnime] = useState(null);
    const [isLoadingSeries, setLoadingSeries] = useState(false);
    const [isEditingSeriesMode, setEditingSeriesMode] = useState(false);
    const [animeDetails, setAnimeDetails] = useState({
        id: 0,
        thumb: '',
        name: 'unknown',
        description: '',
        productionDates: '',
        genres: '',
        announce: '',
        pg: 0,
        createdAt: 0,
        rating: 0.0,
        available: false,
        movie: false, // New field for movie status
        hq: false // New field for HQ status
    });

    const [saveMessage, setSaveMessage] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [thumbFile, setThumbFile] = useState(null); // State to store the selected thumbnail file
    const [seriesList, setSeriesList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await ApiService.getAnimeList();

                if (data.type === "success") {
                    setAnimeList(data.data);
                }



            } catch (error) {
                console.error('Error fetching anime list:', error);
            }
        };
        document.title = "Админ панель";
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
        };
        fetchData();
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    const handleSelectAnime = (anime) => {
        setSelectedAnime(anime);
        setAnimeDetails(anime);
        setSaveMessage('');
        handleLoadSeries(anime.id);

    };

    const handleLoadSeries = async (id) => {
        if (id == undefined) return;
        setLoadingSeries(true);
        var seriesData = await ApiService.getAnimeSeriesList(id); // Fetch series list for the first anime
        if (seriesData.type === "success") {
            setSeriesList(seriesData.data);
            filterSeries(selectedStatus, selectedSeason, seriesData.data);
            setLoadingSeries(false);
        }
    }

    const handleSaveAnime = async () => {
        try {
            const resp = await ApiService.editAnime(animeDetails);
            await handleUploadThumb();
            if (resp.type === "success") {
                setSaveMessage('Сохранено');
            } else {
                setSaveMessage('Ошибка сохранения. Перепроверьте поля');
            }

            const updatedAnimeList = await ApiService.getAnimeList();
            if (updatedAnimeList.type === "success") {
                setAnimeList(updatedAnimeList.data);
            }

        } catch (error) {
            console.error('Error updating anime details:', error);
            setSaveMessage('Ошибка сохранения. (запрос не отправлен)');
        }
    };

    const handleCreateAnime = async () => {
        try {
            const newAnime = await ApiService.editAnime({
                thumb: '',
                name: 'unknown',
                productionDates: '',
                description: '',
                genres: '',
                pg: 0,
                createdAt: 0,
                rating: 0.0,
                available: false
            });

            handleSelectAnime(newAnime);
            const updatedAnimeList = await ApiService.getAnimeList();
            if (updatedAnimeList.type === "success") {
                setAnimeList(updatedAnimeList.data);
            }
        } catch (error) {
            console.error('Error creating new anime:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAnimeDetails({ ...animeDetails, [name]: value });
    };

    const handleFileChange = (e) => {
        setThumbFile(e.target.files[0]);
    };

    const handleUploadThumb = async () => {
        try {
            if (thumbFile == null) return;

            var response = await ApiService.uploadThumb(thumbFile, animeDetails.id)




            // Update anime details with the new thumb URL
            setAnimeDetails({ ...animeDetails, thumb: response.data });

        } catch (error) {
            console.error('Error uploading thumbnail:', error);
        }
        setThumbFile(null);
    };

    const filteredAnimeList = animeList.filter(anime => {
        if (anime.name === null || anime.name === undefined) {
            return true;
        }
        return anime.name.toLowerCase().includes(searchQuery.toLowerCase()) || String(anime.id).includes(searchQuery);
    });

    const getStatusText = (status) => {
        switch (status) {
            case 0:
                return "Загрузка";
            case 1:
                return "Ожидание обработки";
            case 2:
                return "Обработка";
            case 3:
                return "Доступна";
            case 4:
                return "Недоступна";
            case 5:
                return "Ошибка генерации превью";
            case 6:
                return "Невозможно обработать";
            case 7:
                return "Файл не найден. Рекомендовано к удалению";
            default:
                return "Неизвестно";
        }
    }

    const [filteredSeriesList, setFilteredSeriesList] = useState(seriesList);
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [selectedSeason, setSelectedSeason] = useState('all');

    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value);
        filterSeries(e.target.value, selectedSeason, seriesList);
    };

    const handleSeasonChange = (e) => {
        setSelectedSeason(e.target.value);
        filterSeries(selectedStatus, e.target.value, seriesList);
    };

    const filterSeries = (status, season, seriesList) => {
        let filteredList = seriesList;

        if (status !== 'all') {
            filteredList = filteredList.filter(series => series.status === parseInt(status));
        }

        if (season !== 'all') {
            filteredList = filteredList.filter(series => series.season === parseInt(season));
        }

        setFilteredSeriesList(filteredList);
    };

    function formatSize(size) {
        if (size >= 1024 * 1024 * 1024) {
          return (size / (1024 * 1024 * 1024)).toFixed(2) + " GB";
        } else {
          return (size / (1024 * 1024)).toFixed(2) + " MB";
        }
      }

    const handleDeleteAnime = async (id) => {
        try {
            const resp = await ApiService.deleteSeries(id);

            if (resp.type === "success") {

                handleLoadSeries(selectedAnime.id);
            } else {
                console.error('Error deleting anime series:', resp.message);
            }
        } catch (error) {
            console.error('Error deleting anime series:', error);
        }
    };


    const showToken = () => {
        alert("Ваш секретный токен: " + localStorage.getItem("sessionKey"))
    }

    const handleEditSeries = async (series) => {
        try {
            const resp = await ApiService.setSeries(series.id, series.season, series.seriesNum);

            if (resp.type === "success") {


            } else {
                alert("Ошибка: " + JSON.stringify(resp));
                console.error('Error editing anime series:', resp.message);
            }
        } catch (error) {
            console.error('Error editing anime series:', error);
        }
    };


    return (
        <div className="admin-page-app">

            <Header scrolled={scrollPosition > 0} />

            <div className="admin-page">

                <div className="anime-list">

                    <div className="flex">
                        <h2>Список </h2>
                        <div className='flex'>
                            <button className='button-sec' onClick={() => showToken()}>Показать мой токен</button>
                            <button onClick={handleCreateAnime}>Создать</button>
                        </div>
                    </div>
                    <input
                        type="text"
                        id="searchInput"
                        placeholder="Поиск..."

                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />

                    <div className="anime-list-container">
                        <ul>
                            {filteredAnimeList.map((anime) => (
                                <li key={anime.id} onClick={() => handleSelectAnime(anime)}>
                                    {anime.name != null ? anime.name.length === 0 ? "Нет названия" : anime.name : "говно"} {" (id " + anime.id + ")"}
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>

                <div className="anime-details">
                    <h2>Сведения</h2>

                    {selectedAnime && (
                        <>
                            <p>ID: {selectedAnime.id}, created  {selectedAnime.createdAt}</p>
                            <input
                                type="text"
                                name="name"
                                placeholder="Название"
                                value={animeDetails.name}
                                onChange={handleInputChange}
                            />
                            <div>
                                <textarea
                                    name="description"
                                    placeholder="Описание"
                                    value={animeDetails.description}
                                    onChange={handleInputChange}
                                />
                            </div>


                            <div>
                                {(
                                    <img className='thumb-image' src={`https://api.animix.lol/anime/getThumb/${animeDetails.id}_thumb.png`} alt="Thumbnail" />
                                )}

                            </div>


                            <label htmlFor="background-upload" className="upload-label">
                                Обновить превью
                                <input id="background-upload" type="file" accept="image/*" onChange={handleFileChange} />
                            </label>

                            <input
                                type="text"

                                name="productionDates"
                                placeholder="Даты выпуска"
                                value={animeDetails.productionDates}
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="genres"
                                placeholder="Жанры"
                                value={animeDetails.genres}
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="pg"
                                placeholder="Рейтинг PG"
                                value={animeDetails.pg}
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="rating"
                                placeholder="Рейтинг"
                                value={animeDetails.rating}
                                onChange={handleInputChange}
                            />

                            <input
                                type="text"
                                name="blacklistedCountries"
                                placeholder="Недоступные страны (RU, BY...)"
                                value={animeDetails.blacklistedCountries}
                                onChange={handleInputChange}
                            />

                            <input
                                type="text"
                                name="announce"
                                placeholder="Анонс (сообщение на главной странице)"
                                value={animeDetails.announce}
                                onChange={handleInputChange}
                            />

                            <div>
                                <input
                                    type="checkbox"
                                    id="movie"
                                    name="movie"
                                    checked={animeDetails.movie}
                                    onChange={(e) => setAnimeDetails({ ...animeDetails, movie: e.target.checked })}
                                />
                                <label className='label-switch' htmlFor="movie">Это фильм</label>
                            </div>

                            <div>
                                <input
                                    type="checkbox"
                                    id="hq"
                                    name="hq"
                                    checked={animeDetails.hq}
                                    onChange={(e) => setAnimeDetails({ ...animeDetails, hq: e.target.checked })}
                                />
                                <label className='label-switch' htmlFor="hq">Высокое качество</label>
                            </div>

                            <input
                                type="checkbox"
                                id="available"
                                name="available"
                                checked={animeDetails.available}
                                onChange={(e) => setAnimeDetails({ ...animeDetails, available: e.target.checked })}
                            />


                            <label className='label-switch' htmlFor="available">Доступно</label>

                            <button onClick={handleSaveAnime}>Сохранить</button>
                            {saveMessage && <div className="text">{saveMessage}</div>}
                            <div>
                                <label htmlFor="statusFilter">Фильтровать по статусу</label>
                                <select id="statusFilter" value={selectedStatus} onChange={handleStatusChange}>
                                    <option value="all">Любой</option>
                                    <option value="0">Загрузка</option>
                                    <option value="1">Ожидание обработки</option>
                                    <option value="2">Обработка</option>
                                    <option value="3">Доступна</option>
                                    <option value="4">Недоступна</option>
                                    <option value="5">Ошибка генерации превью</option>
                                    <option value="6">Невозможно обработать</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="seasonFilter">Фильтровать по сезону</label>
                                <select id="seasonFilter" value={selectedSeason} onChange={handleSeasonChange}>
                                    <option value="all">Любой</option>
                                    {/* Assuming seriesList is an array of objects with a season property */}
                                    {[...new Set(seriesList.map(series => series.season))]
                                        .sort((a, b) => a - b)
                                        .map(season => (
                                            <option key={season} value={season}>{season}</option>
                                        ))}
                                </select>
                                <input
                                    type="checkbox"
                                    id="editSeries"
                                    name="editSeries"
                                    checked={isEditingSeriesMode}
                                    onChange={(e) => setEditingSeriesMode(e.target.checked)}
                                />
                                <label className='label-switch' htmlFor="editSeries">Редактирование</label>

                            </div>


                            {isLoadingSeries && <div className="loader" />}
                            <ul>
                                {filteredSeriesList.map((series, index) => (
                                    <div key={index} className="card">
                                        <div className="image-container">
                                            <img src={`https://api.animix.lol/anime/getThumb/${series.privateId}_thumb.png`} alt="Thumbnail" />
                                        </div>
                                        <div className="card-details">
                                            <p><strong>{series.seriesNum + " серия, " + series.season + " сезон"}</strong> </p>
                                            <p><strong>Секретный ID:</strong> {series.privateId + " (id " + series.id + ")"} </p>
                                            <p><strong>Загружено:</strong> {series.uploadedAt}</p>
                                            <p><strong>Приоритет:</strong> {series.processingPriority}</p>
                                            <p><strong>Статус:</strong> {getStatusText(series.status)}</p>
                                            <p><strong>Качество:</strong> {series.videoQuality}</p>
                                            <p><strong>Размер:</strong> {formatSize(series.size)}</p>
                                            {isEditingSeriesMode && <div>  <div>
                                                <label htmlFor={`seriesNum_${index}`}>Серия:</label>
                                                <input type="text" id={`seriesNum_${index}`} onChange={(e) => series.seriesNum = e.target.value} />
                                            </div>
                                                <div>
                                                    <label htmlFor={`season_${index}`}>Сезон:</label>
                                                    <input type="text" id={`season_${index}`} onChange={(e) => series.season = e.target.value} />
                                                </div></div>
                                            }

                                            {isEditingSeriesMode && <button className='button-primary' onClick={() => handleEditSeries(series)}>Применить</button>}
                                            <button className='button-sec' onClick={() => handleDeleteAnime(series.id)}>Удалить сущность (не файл)</button>
                                        </div>
                                    </div>
                                ))}
                            </ul>
                        </>
                    )}

                </div>

            </div>


            <Footer />

        </div>
    );
};

export default AdminPage;
