import React, { useEffect, useRef, useState } from 'react';
import './Header.css';
import logo from './../assets/logo_150.svg';
import ProfilePic from './profilepic/ProfilePic';
import ApiService from '../services/ApiService';
import HeaderMobile from './HeaderMobile';
import ProfilePopup from './ProfilePopup';
import NotifyIcon from '../assets/ic_notification_header.svg';
import NotifyIconNew from '../assets/ic_notification_header_new.svg';
import NotificationPopup from './NotificationPopup';
import leaderboardIcon from '../assets/ic_leaderboard.svg';
import homeIcon from '../assets/ic_home.svg';
import collectionsIcon from '../assets/ic_collection.svg';
import { useNavigate } from 'react-router-dom';
import premium from '../assets/ic_premium_blank.svg';
import AnimixPremiumPopup from './premium/AnimixPremiumPopup';

const Header = ({ scrolled }) => {
    const [searchResults, setSearchResults] = useState({ anime: [], user: [] });
    const [searchQuery, setSearchQuery] = useState('');
    const searchRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupNot, setShowPopupNot] = useState(false);
    const [showNewNot, setShowNewNot] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [showPremium, setShowPremium] = useState(false);

    const navigate = useNavigate();

    const togglePopup = () => {
        setShowPopup(!showPopup);
        setShowPopupNot(false);
    };
    const [isMobile, setIsMobile] = useState(false);

    const handleSearch = async (query) => {
        setSearchQuery(query);
        if (query.length < 3) return;
        try {
            const results = await ApiService.search(query);
            if (results.type === "success") {
                setSearchResults(results.data);
            }
        } catch (error) {
            console.error('Error occurred while searching:', error);
            setSearchResults({ anime: [], user: [] });
        }
    };

    var notificationFetched = false;

    useEffect(() => {
        const fetchNotifications = async () => {
            if (notificationFetched) return;
            notificationFetched = true;
            try {
                const data = await ApiService.getNotifications(0);
                if (data.data[0].id !== localStorage.getItem("seenNotId")) {
                    setShowNewNot(true);
                } else {
                    setShowNewNot(false);
                }
            } catch (error) {
                //  console.error('Failed to fetch notifications:', error);
            }
        };
        fetchNotifications();
    }, []);

    const clearSearchResults = () => {
        setSearchResults({ anime: [], user: [] });
    };

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            clearSearchResults();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1100);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const sessionKey = localStorage.getItem('sessionKey');

    return (
        <div>
            {isMobile ? <HeaderMobile /> :
                <header className={`header ${scrolled ? 'headerScrolled' : ''}`}>
                    <div
                        className="logo-menu-container"
                        onMouseEnter={() => setShowMenu(true)}
                        onMouseLeave={() => setShowMenu(false)}
                    >
                        <a style={{cursor: "pointer"}}  onClick={() => {navigate("/")}}>
                            <img className="logo" src={logo} alt="Logo" />
                        </a>

                        <div className={`home-menu-header ${showMenu ? `` : 'hidden'}`}>
                            <div className='menu-item' onClick={() => { navigate("/") }}>
                                <img src={homeIcon} />
                                <a>Главная</a>
                            </div>
                            <div className='menu-item' onClick={() => { navigate("/leaderboard") }}>
                                <img src={leaderboardIcon} />
                                <a>Лидерборд</a>
                            </div>
                            {sessionKey ? (
                                <div className='menu-item' onClick={() => { navigate(`/collection/${localStorage.getItem("profileId")}`) }}>
                                    <img src={collectionsIcon} />
                                    <a>Моя коллекция</a>
                                </div>
                            ) : ''}
                        </div>

                    </div>
                    <div className="searchBar" ref={searchRef}>
                        <input
                            type="text"
                            placeholder="Поиск"
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                        {searchResults.anime.length > 0 || searchResults.user.length > 0 ? (
                            <div className="searchResultsBox">
                                {searchResults.anime.map((anime) => (
                                    <a className='search-result' key={anime.id} style={{cursor: "pointer"}}  onClick={() => { if (window.location.pathname.includes(`/anime`)) {
                                        navigate(`/anime/${anime.id}`);
                                        navigate(0);  // Refreshes the page if already on the same route
                                      } else {
                                        navigate(`/anime/${anime.id}`);
                                      }}}>
                                        <p className='search-result-text'>{anime.name}</p>
                                        <p className={`search-rating ${anime.rating >= 7.5 ? anime.rating >= 9 ? "premium" : "good" : ""}`}>★ {anime.rating}</p>
                                    </a>
                                ))}
                                {searchResults.user.map((user) => (
                                    <a className='search-result' key={user.id}  style={{cursor: "pointer"}}  onClick={() => {navigate(`/profile/${user.nickname}`)}}
                                   >
                                        <ProfilePic className="accountImage" account={user} style={{ scale: 0.2 }} />
                                        <p className='search-result-text' style={{ marginLeft: 10 }}>{user.nickname}</p>
                                    </a>
                                ))}
                            </div>
                        ) : ""}
                    </div>
                    <div className='rightContainer'>
                        {localStorage.getItem("subscribed") !== "true" ?     <div className='header-prem' onClick={() => {
                            setShowPremium(true);
                        }}>
                            <img src={premium} />
                            <h5>Премиум</h5>
                        </div> : ''}
                    
                        {sessionKey ? (
                            <div>

                                <img src={showNewNot ? NotifyIconNew : NotifyIcon} alt="Play Icon" className='notify-icon'
                                    onClick={() => {
                                        setShowPopupNot(!showPopupNot);
                                        setShowPopup(false);
                                    }} />
                                <div className="profile-container" onClick={togglePopup} style={{ cursor: 'pointer' }}>
                                    <ProfilePic className="accountImage" />
                                </div>
                            </div>
                        ) : (
                            <a className="loginButton" style={{cursor: "pointer"}} onClick={() => { navigate("/login") }}>Войти</a>
                        )}

                        <ProfilePopup visible={showPopup} />
                        {showPopupNot ? <NotificationPopup visible={showPopupNot} onClose={() => setShowPopupNot(false)} /> : ""}
                        {showPremium ? <AnimixPremiumPopup onClose={() => {
                            setShowPremium(false);
                        }}/> : ""}
                    </div>
                </header>}
        </div>
    );
};

export default Header;
