import React, {useState, useEffect, useRef} from 'react';
import './animix-premium.css';
import adFreeIcon from '../../assets/ad-free.svg';
import profileFrameIcon from '../../assets/profile-frame.svg';
import neuralRecommendationsIcon from '../../assets/neural-recommendations.svg';
import hdIcon from '../../assets/ic_diamond.svg';
import animixPremiumLogo from '../../assets/animix-premium-logo.svg'; // Assuming you have the logo SVG
import ApiService from '../../services/ApiService'; // Assuming this is the correct path to your ApiService

const AnimixPremiumPopup = ({onClose}) => {
    const [isVisible, setIsVisible] = useState(true);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const popupRef = useRef(null);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleSubscribe = async (isMonthly) => {
        if (!validateEmail(email)) {
            setEmailError('Пожалуйста, укажите корректный адрес почты');
            return;
        }

        setEmailError('');

        try {
            const checkoutLink = await ApiService.getCheckout(isMonthly, email);
            window.location.href = checkoutLink.data;
        } catch (error) {
            console.error('Error fetching checkout link:', error);
            // Optionally, display an error message to the user
        }
    };

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setIsVisible(false);
            if (onClose) onClose();
        }
    };

    const fetchEmail = async () => {
        try {
            const data = await ApiService.getEmail();
            if (data.type == "success") {
                setEmail(data.data);
            }
        } catch {
        }
    };
    useEffect(() => {
        // Check for sessionKey
        const sessionKey = localStorage.getItem('sessionKey');
        if (!sessionKey) {
            window.location.href = '/login'; // Navigate to login if no sessionKey
        }

        fetchEmail();
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (!isVisible) return null;

    return (
        <div className="prem-popup-container">
            <div className="prem-popup-content" ref={popupRef}>
                <img src={animixPremiumLogo} alt="Animix Premium" className="prem-popup-logo"/>
                <div>
              
                </div>
                <div className="prem-popup-feature">
                    <img src={adFreeIcon} alt="Ad Free"/>
                    <span>Без рекламы</span>
                </div>
                <div className="prem-popup-feature">
                    <img src={hdIcon} alt="Special Profile Frame"/>
                    <span>Эксклюзивное высокое качество (2K, 4K) </span>
                </div>
                <div className="prem-popup-feature">
                    <img src={profileFrameIcon} alt="Special Profile Frame"/>
                    <span>Полная кастомизация профиля</span>
                </div>
                <div className="prem-popup-feature">
                    <img src={neuralRecommendationsIcon} alt="Neural Recommendations"/>
                    <span>Нейронные рекомендации</span>
                </div>
                <a>А ещё рамки, фоны и многое другое</a>
            
                <div className="prem-popup-pricing">
                    <button
                        className="monthly-btn"
                        onClick={() => handleSubscribe(true)}
                    >
                        Подписаться на месяц <span>199 Р</span>
                    </button>
                    <button
                        className="yearly-btn"
                        onClick={() => handleSubscribe(false)}
                    >
                        Подписаться на год <span>1990 Р</span> <span>~-20%</span>
                    </button>
                </div>
                <div className="prem-popup-email">
                    <input
                        type="email"
                        style={{margin: 0}}
                        placeholder="Введите почту для чека"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {emailError && <p className="email-error">{emailError}</p>}
                </div>
                <p className="prem-popup-note">Вы подписываетесь на рекуррентные списания Aiko, отключить их можно будет
                    в настройках профиля</p>
            </div>
        </div>
    );
};

export default AnimixPremiumPopup;
