import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory from React Router
import SeriesCardComponent from '../components/seriescard/SeriesCardComponent';

const SeasonComponent = ({ animeData, seriesData, onSelectAnime, currentId }) => {
    const [selectedSeason, setSelectedSeason] = useState(0);
    const [seriesCount, setSeriesCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if (seriesData == null) return;
        // Calculate series count when seriesData changes
        let count = 0;
        Object.values(seriesData).forEach(season => {
            count += season.length;
        });
        setSeriesCount(count);
    }, [seriesData]);

    const handleSeasonChange = (index) => {
        setSelectedSeason(index);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <div>
          
            <div>
            <input
            type="number"
            className='series-find-input'
            placeholder="Номер"

            value={searchQuery}
            onChange={handleSearchChange}
          />
                {seriesCount === 0 ? <p>Тут пока ничего нет, но скоро будет {"<3"}</p> : seriesCount === 1 ? "" :  Object.keys(seriesData).map((seasonNumber, index) => (
                    <button
                        key={seasonNumber}
                        className={selectedSeason === index ? 'tab-button selected' : 'tab-button'}
                        onClick={() => handleSeasonChange(index)}
                    >
                        Сезон {seasonNumber}
                    </button>
                    
                ))}
            </div>
        
            {Object.keys(seriesData).map((seasonNumber, index) => (
                <div key={seasonNumber} style={{ display: selectedSeason === index ? 'block' : 'none' }} >
                    <SeriesComponent
                        seriesList={searchQuery.length == 0  ? seriesData[seasonNumber] : seriesData[seasonNumber].filter(series => series.seriesNum.toString().includes(searchQuery))}
                        animeData={animeData}
                        onSelectAnime={onSelectAnime}
                        currentId={currentId}
                        single={seriesCount === 1}
                    />
                </div>
            ))}
        </div>
    );
};

const SeriesComponent = ({ seriesList, animeData, onSelectAnime, currentId, single }) => {
    return (
        <div className="series-container">
            {seriesList.map(series => (
                <div key={series.privateId}>
                    <SeriesCardComponent series={series} single={animeData.movie} anime={animeData} onSelectAnime={onSelectAnime} currentId={currentId} />
                </div>
            ))}
        </div>
    );
};

export default SeasonComponent;
