import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import Link and useNavigate
import './HeaderMobile.css';
import logo from './../assets/logo_150.svg';
import ProfilePic from './profilepic/ProfilePic';
import ApiService from '../services/ApiService';
import ProfilePopup from './ProfilePopup';
import NotifyIcon from '../assets/ic_notification_header.svg';
import NotificationPopup from './NotificationPopup';

const HeaderMobile = ({ scrolled }) => {
    const [searchResults, setSearchResults] = useState({ anime: [], user: [] });
    const [searchQuery, setSearchQuery] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);
    const searchRef = useRef(null); // Ref to the search input
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupNot, setShowPopupNot] = useState(false);

    const navigate = useNavigate();

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const handleSearch = async (query) => {
        setSearchQuery(query);
        if (query.length < 2) return; 
        try {
            const results = await ApiService.search(query);
            if (results.type === "success") {
                setSearchResults(results.data);
            }
        } catch (error) {
            console.error('Error occurred while searching:', error);
            setSearchResults({ anime: [], user: [] });
        }
    };

    const clearSearchResults = () => {
        setSearchResults({ anime: [], user: [] });
    };

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            clearSearchResults();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (menuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [menuOpen]);

    const sessionKey = localStorage.getItem('sessionKey');

    return (
        <div>
            <div className={`header-menu-bar ${!menuOpen ? 'hidden' : ''}`}>
                <div className="searchBarMobile" ref={searchRef}>
                    <input
                        type="text"
                        placeholder="Поиск аниме"
                        value={searchQuery}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                    {searchResults.anime.length > 0 || searchResults.user.length > 0 ? (
                        <div className="searchResultsBox">
                            {searchResults.anime.map((anime) => (
                                <div
                                    className='search-result'
                                    key={anime.id}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => { navigate(`/anime/${anime.id}`); }}
                                >
                                    <p className='search-result-text'>{anime.name}</p>
                                    <p className={`search-rating ${anime.rating >= 7.5 ? anime.rating >= 9 ? "premium" : "good" : ""}`}>★ {anime.rating}</p>
                                </div>
                            ))}
                            {searchResults.user.map((user) => (
                                <div
                                    className='search-result'
                                    key={user.id}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => { navigate(`/profile/${user.nickname}`); }}
                                >
                                    <ProfilePic className="accountImage" account={user} style={{ "scale": 0.2 }} />
                                    <p className='search-result-text' style={{ "marginLeft": 10 }}>{user.nickname}</p>
                                </div>
                            ))}
                        </div>
                    ) : ""}
                </div>
                {sessionKey ? (
                    ""
                ) : (
                    <Link className="loginButton" to="/login">Войти</Link>
                )}
                <Link to="/leaderboard"><h3>Лидерборд</h3></Link> {/* Replace a with Link */}
                <Link to={`/collection/${localStorage.getItem("profileId")}`}><h3>Моя коллекция</h3></Link> {/* Replace a with Link */}
            </div>
            <header className={`headerMobile ${scrolled ? 'headerScrolled' : ''}`}>
                <Link to="/">
                    <img className="logo" src={logo} alt="Logo" /> {/* Replace a with Link */}
                </Link>
                <div className='rightContainer'>
                    {sessionKey ? (
                        <div>
                            <img
                                src={NotifyIcon}
                                alt="Play Icon"
                                className='notify-icon'
                                onClick={() => {
                                    setShowPopupNot(!showPopupNot);
                                    setShowPopup(false);
                                }}
                            />
                            <div className="profile-container" onClick={togglePopup}>
                                <ProfilePic className="accountImage" />
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <ProfilePopup visible={showPopup} />
                    <NotificationPopup visible={showPopupNot} onClose={() => setShowPopupNot(false)} />
                </div>
                <div className='menuIcon' onClick={() => setMenuOpen(!menuOpen)}>
                    <div className='menuLine'></div>
                    <div className='menuLine'></div>
                    <div className='menuLine'></div>
                </div>
            </header>
        </div>
    );
};

export default HeaderMobile;
